<template>
  <div class="desing-detail">
    <div class="w content">
      <div class="title">{{ form.name }}</div>
      <div class="bor"></div>

      <div class="text" v-html="form.briefText"></div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" :class="`swiper-slide${index + 1}`" v-for="(item, index) in imgList" :key="index">
            <img :src="item" alt="">
          </div>
        </div>
      </div>
      <div class="back curr" @click="back">
        <img src="@/assets/images/news-image/back.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, reactive, toRefs } from 'vue';
import apis from '@/request/apis';
import { useRouter } from 'vue-router';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';

export default {
  props: ['id', 'scrollY2'],
  setup(props) {
    const router = useRouter();
    const data = reactive({
      form: {},
      imgList: [],
    });
    const initSwiper = () => {
      data.swiper = new Swiper('.swiper-container', {
        grabCursor: true,
        loop: true,
        speed: 1000,
        autoplay: {
          disableOnInteraction: false,
        },
      });
    };
    const defailt = async () => {
      const res = await apis.receptionClassicCases.getDetail({
        id: props.id,
      });
      data.form = res;

      if (data.form.linkedPic) {
        data.imgList = data.form.linkedPic.split(',');
        nextTick(() => {
          initSwiper();
        });
      }
    };

    defailt();

    const back = () => {
      router.back();
    };
    return {
      ...toRefs(data),
      back,
    };
  },
};
</script>

<style lang="scss" scoped>
.desing-detail {
  margin-top: 5.208vw;
  margin-bottom: 3.438vw;
  position: relative;
  overflow: hidden;
  .content {
    .title {
      height: 1.667vw;
      font-size: 1.25vw;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      line-height: 1.458vw;
    }
    .bor {
      width: 100%;
      height: 1px;
      background-color: #DFDFDF;
      margin-top: 1.146vw;
      margin-bottom: 1.979vw;
    }
    .text {
      font-size: 0.938vw;
      font-family: Microsoft New Tai Lue-Regular, Microsoft New Tai Lue;
      font-weight: 400;
      color: #333333;
      line-height: 1.354vw;
    }
    .box-img {
      img {
        display: block;
        width: 50%;
        object-fit: cover;
        margin: 0 auto;
        margin-top: 1.458vw;
      }
    }
    .swiper-container {
      margin-top: 4vw;
      margin-bottom: 4vw;
      width: 50vw;
      height: 25vw;
      position: relative;
    }
    .swiper-slide {
      background-color: #fff;
      transform-style: preserve-3d;
      transition: transform 0.6s;
      color: #fff;
      text-align: center;
      img {
        width: 50vw;
        height: 25vw;
        object-fit: cover;
      }
    }
    .back {
      width: 5.208vw;
      height: 2.083vw;
      margin-top: 1.458vw;
      float: right;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .desing-detail {
    margin-top: 10vw;
    margin-bottom: 3.438vw;
    position: relative;
    overflow: hidden;
    .content {
      .title {
        height: auto;
        font-size: 3.2vw;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #000000;
        line-height: 4.267vw;
        margin: 0 2vw;
      }
      .bor {
        width: 100%;
        height: 1px;
        background-color: #DFDFDF;
        margin-top: 1.146vw;
        margin-bottom: 1.979vw;
      }
      .text {
        font-size: 1.867vw;
        font-family: Microsoft New Tai Lue-Regular, Microsoft New Tai Lue;
        font-weight: 400;
        color: #333333;
        line-height: 4.267vw;
        padding: 0 2vw;
      }
      .box-img {
        img {
          display: block;
          width: 50%;
          object-fit: cover;
          margin: 0 auto;
          margin-top: 1.458vw;
        }
      }
      .swiper-container {
        margin-top: 4vw;
        margin-bottom: 4vw;
        width: 100vw;
        height: 45vw;
        position: relative;
      }
      .swiper-slide {
        background-color: #fff;
        transform-style: preserve-3d;
        transition: transform 0.6s;
        color: #fff;
        text-align: center;
        img {
          width: 100vw;
          height: 45vw;
          object-fit: cover;
        }
      }
      .back {
        width: 15.208vw;
        height: 6.083vw;
        margin-top: 1.458vw;
        margin-right: 4vw;
        float: right;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
